import actions from './actions';
import settings from '../../settings';

const initState = {
  validatingLoanNumber: false,
  loader: false,
  loanNumber: null,
  otpType: 'SMS',
  validatingOTP: false,
  resendingOTP: false,
  customerID: null,
  accessToken: null,
  loanValidateErrorMessage: null,
  paymentDetails: {},
  initiatingBillDeskRequest: false,
  transactionStatus: null,
  transactionStatusLoader: false,
  mobileNumber: null,
  email: null,
  validateOTPErrorMessage: null,
  billDeskInitErrorMessage: null,
  redirectPayload: null,
  loanAmount: 0,
  redirectToAccountDetailsPage: true,
  tokenValidityDuration: 0,
  OTPDuration: 0,
  tokenType: '',
  paymentDetailsLoader: false,
  OtpFailured: false,
};

export default function userDetailsReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_ACCOUNT_VALIDATION_DETAILS: {
      return {
        ...state,
        otpType: action.otpType,
        accountDetails: action.payload,
      };
    }
    case actions.VALIDATE_LOAN_ACCOUNT_NUMBER: {
      return {
        ...state,
        validatingLoanNumber: true,
        loanValidateErrorMessage: null,
        OTPDuration: 0,
        resendingOTP: action.isFromResendOTP,
        validateOTPErrorMessage: null,
      };
    }
    case actions.VALIDATE_LOAN_ACCOUNT_NUMBER_SUCCESS: {
      return {
        ...state,
        validatingLoanNumber: false,
        customerID: action.payload.out_msg.CustomerId,
        loanNumber: action.payload.out_msg.AccountNo,
        loanValidateErrorMessage: null,
        redirectToAccountDetailsPage: false,
        OTPDuration: settings.OTP_DURATION,
        resendingOTP: action.isFromResendOTP ? false : state.resendingOTP,
        validateOTPErrorMessage: null,

        OtpFailured: false,
      };
    }
    case actions.VALIDATE_LOAN_ACCOUNT_NUMBER_FAILURE: {
      let errorMessage = action.payload
        ? action.payload?.out_msg?.ErrorMessage
        : 'Something went wrong, please try again';
      if (
        action.payload &&
        action.payload?.out_msg?.ErrorMessage === 'Invalid Data'
      ) {
        errorMessage =
          'Account Number is not matching with provided Mobile Number or Email ID';
      }
      if (action.status && action.status === 429) {
        errorMessage =
          'The OTP generating limit has been exceeded. Please try after some time.';
      }
      return {
        ...state,
        validatingLoanNumber: false,
        loanValidateErrorMessage: action.isFromResendOTP ? null : errorMessage,
        resendingOTP: action.isFromResendOTP ? false : state.resendingOTP,
        validateOTPErrorMessage: action.isFromResendOTP ? errorMessage : null,
      };
    }
    case actions.SET_LOAN_NUMBER: {
      return {
        ...state,
        loanNumber: action.payload,
      };
    }
    case actions.VALIDATE_OTP: {
      return {
        ...state,
        validatingOTP: true,
        validateOTPErrorMessage: null,
      };
    }
    case actions.VALIDATE_OTP_SUCCESS: {
      return {
        ...state,
        validateOTPErrorMessage: null,
        accessToken: action.payload?.out_msg?.accessToken,
        tokenValidityDuration: action.payload?.out_msg?.expires,
        tokenType: action.payload?.out_msg?.tokenType,
        validatingOTP: true,

        OtpFailured: false,
      };
    }
    case actions.VALIDATE_OTP_FAILURE: {
      return {
        ...state,
        validatingOTP: false,
        validateOTPErrorMessage: action.payload
          ? action.payload.out_msg.ErrorMessage === 'Invalid OTP'
            ? 'Entered OTP is invalid. Kindly request Resend OTP'
            : action.payload.out_msg.ErrorMessage
          : null,

        OTPDuration: 0,
        OtpFailured: true,
      };
    }
    case actions.GET_PAYMENT_DETAILS: {
      return {
        ...state,
        paymentDetailsLoader: true,
      };
    }
    case actions.GET_PAYMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        paymentDetailsLoader: false,
        paymentDetails: action.payload.out_msg,
        validatingOTP: false,
      };
    }
    case actions.GET_PAYMENT_DETAILS_FAILURE: {
      return {
        ...state,
        paymentDetailsLoader: false,
        validatingOTP: false,
      };
    }
    case actions.BILL_DESK_REDIRECT: {
      return {
        ...state,
        initiatingBillDeskRequest: true,
        billDeskInitErrorMessage: null,
      };
    }
    case actions.BILL_DESK_REDIRECT_SUCCESS: {
      return {
        ...state,
        initiatingBillDeskRequest: false,
        billDeskInitErrorMessage: null,
      };
    }
    case actions.BILL_DESK_REDIRECT_FAILURE: {
      return {
        ...state,
        initiatingBillDeskRequest: false,
        billDeskInitErrorMessage: action.payload
          ? action.payload.out_msg.ErrorDesc
          : null,
      };
    }
    case actions.GET_TRANSACTION_STATUS: {
      return {
        ...state,
        transactionStatus: null,
        transactionStatusLoader: true,
      };
    }
    case actions.GET_TRANSACTION_STATUS_SUCCESS: {
      let transactionStatus;
      if (action.payload.out_msg.TranactionDtls.length) {
        transactionStatus = action.payload.out_msg.TranactionDtls[0].STATUS;
      } else {
        transactionStatus = 'success';
      }
      return {
        ...state,
        transactionStatus:
          transactionStatus === 'null' || transactionStatus === ''
            ? null
            : 'success',
        transactionStatusLoader: false,
        validatingOTP: true,
      };
    }
    case actions.GET_TRANSACTION_STATUS_FAILURE: {
      return {
        ...state,
        transactionStatus: null,
        transactionStatusLoader: false,
        validatingOTP: false,
      };
    }
    case actions.SET_MOBILE_AND_EMAIL_DETAILS: {
      return {
        ...state,
        mobileNumber: action.mobileNumber,
        email: action.email,
      };
    }
    case actions.SET_BILL_DESK_REDIRECT_DATA: {
      return {
        ...state,
        redirectPayload: action.redirectPayload,
        loanAmount: action.loanAmount,
      };
    }
    case actions.RESET_REDUCER_STATE: {
      return initState;
    }
    default:
      return state;
  }
}
