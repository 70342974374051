import React from 'react';
import Logo from '../images/logo-kvb-white.svg';
import { Link } from 'react-router-dom';

export default class NavBar extends React.PureComponent {
  render() {
    return (
      <nav className="navbar">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="navbar-header">
                <Link className="navbar-brand" to="/">
                  <img alt="KVB Logo - White" src={Logo} />
                  <p className="navbar-text navbar-right" />
                </Link>
                <div className="navbar-brand-text">
                  <h4>Karur Vysya Bank</h4>
                  <span>Smart way to bank</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}
