import React, { Fragment } from 'react';
import UserDetailsForm from '../../components/UserDetailsForm';
import { connect } from 'react-redux';
import actions from '../../redux/userDetails/actions';
import settings from '../../settings';
import { store } from '../../redux/store';

class UserDetails extends React.PureComponent {
  componentDidMount() {
    window.addEventListener('beforeunload', this.preventEvent);
    if (!!this.props.accessToken) {
      store.dispatch({ type: actions.RESET_REDUCER_STATE });
      delete settings.Headers['Authorization'];
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.preventEvent);
  }

  preventEvent = e => {
    // Cancel the event
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = undefined;
  };

  render() {
    const {
      validatingLoanNumber,
      loanValidateErrorMessage,
      loanNumber,
      mobileNumber,
      email,
    } = this.props;
    return (
      <Fragment>
        <UserDetailsForm
          validatingLoanNumber={validatingLoanNumber}
          loanValidateErrorMessage={loanValidateErrorMessage}
          loanNumber={loanNumber}
          mobileNumber={mobileNumber}
          email={email}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {
    validatingLoanNumber,
    loanValidateErrorMessage,
    loanNumber,
    mobileNumber,
    email,
    accessToken,
  } = state.UserDetails;
  return {
    validatingLoanNumber,
    loanValidateErrorMessage,
    loanNumber,
    mobileNumber,
    email,
    accessToken,
  };
}

export default connect(mapStateToProps, actions)(UserDetails);
