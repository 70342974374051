import axios from 'axios';
import { store } from '../redux/store';
import settings from '../settings';

const axiosClient = axios.create();

axiosClient.defaults.baseURL = `${settings.ROOT_URL}next/v1/mw/`;
axiosClient.defaults.headers = settings.Headers;

export function getRequest(URL) {
  return axiosClient.get(`/${URL}`).then(response => response);
}

export function postRequest(URL, payload) {
  const { accessToken, tokenType } = store.getState().UserDetails;
  if (['otp/oauth', 'account/validate'].includes(URL)) {
    delete settings.Headers['Authorization'];
  } else {
    settings.Headers['Authorization'] = `${tokenType} ${accessToken}`;
  }
  return axiosClient.post(`/${URL}`, payload).then(response => response);
}

export function patchRequest(URL, payload) {
  return axiosClient.patch(`/${URL}`, payload).then(response => response);
}

export function deleteRequest(URL) {
  return axiosClient.delete(`/${URL}`).then(response => response);
}
