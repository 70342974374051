import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import asyncComponent from '../helpers/asyncComponent';
import { history, store } from '../redux/store';
import actions from '../redux/userDetails/actions';
import { getQueryParams } from '../helpers/utility';
import NavBar from '../components/NavBar';
import Loader from '../components/Loader';
import UserDetails from './UserDetails/UserDetails';
const Payment = asyncComponent(() => import('./UserDetails/Payment'));
const OTP = asyncComponent(() => import('./UserDetails/OTP'));
const NotFound = asyncComponent(() => import('../containers/NotFound'));
const TransactionStatus = asyncComponent(() =>
  import('../components/TransactionStatus'),
);

class Routes extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
    };
    this.timer = setInterval(this.hideLoader, 2000);
    this.sourceApp = null;
  }

  componentDidMount() {
    this.sourceApp = getQueryParams('ch');
    let loanAcNo = getQueryParams('loanAcNo');
    let mobileNumber = getQueryParams('mob');
    let email = getQueryParams('email');
    if (this.sourceApp === 'DLite') {
      store.dispatch({
        type: actions.SET_LOAN_NUMBER,
        payload: loanAcNo,
      });
      store.dispatch({
        type: actions.SET_MOBILE_AND_EMAIL_DETAILS,
        mobileNumber: mobileNumber,
        email: email,
      });
    }

    window.addEventListener('contextmenu', function(event) {
      event.preventDefault();
      window.alert('Right Click Disabled');
    });
  }

  hideLoader = () => {
    this.setState({
      showLoader: false,
    });
  };

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const { showLoader } = this.state;
    return (
      <Router history={history}>
        {showLoader && <Loader />}
        <Switch>
          <Route exact path="/">
            <NavBar />
            <UserDetails />
          </Route>
          <Route exact path="/validate">
            <NavBar />
            <OTP />
          </Route>
          <Route exact path="/payment">
            <NavBar />
            <Payment />
          </Route>
          <Route exact path="/txnStatus">
            <NavBar />
            <TransactionStatus />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default Routes;
