import React from 'react';
import './styles/global.scss';
import ErrorBoundary from './containers/ErrorBoundary';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import Routes from './containers/Routes';

function App() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <Routes />
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
