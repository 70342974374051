import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import rootSaga from './sagas';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const middlewares = sagaMiddleware;

const appReducer = combineReducers({
  ...reducers,
});

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    })
    : compose;
const getEnhancers = () => {
  if (window.location.href !== 'https://www.kvbbank.net.in/') {
    return composeEnhancers(applyMiddleware(middlewares));
  } else {
    return applyMiddleware(middlewares);
  }
};
const store = createStore(appReducer, getEnhancers());
sagaMiddleware.run(rootSaga);

export { store, history };
