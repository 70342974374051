//UAT
// const settings = {
//   ROOT_URL: 'https://api-sit.kvbank.in/',
//   Headers: { 'Content-Type': 'application/json' },
//   BILL_DESK_REDIRECT_URL: 'https://uat.billdesk.com/pgidsk/PGIMerchantPayment',
//   OTP_DURATION: 120,
// };

// PROD
const settings = {
  ROOT_URL: 'https://www.kvbbank.net.in/',
  Headers: { 'Content-Type': 'application/json' },
  BILL_DESK_REDIRECT_URL: 'https://pgi.billdesk.com/pgidsk/PGIMerchantPayment',
  OTP_DURATION: 120,
};

export default settings;
