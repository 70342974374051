import { useLocation } from 'react-router-dom';

//Helpers

export function callTimer(otpDuration) {
  this.setState({ seconds: otpDuration }, this.callTimer);
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function getQueryParams(key) {
  let query = new URL(window.location.href);
  if (key === 'email') {
    let queryParams = {};
    let query = window.location.search.substring(1);
    let paramsList = query.split('&');
    for (let i = 0; i < paramsList.length; i++) {
      let pair = paramsList[i].split('=');
      queryParams[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }
    return queryParams['email'] || null;
  }
  return query.searchParams.get(key);
}
