import React, { Fragment } from 'react';
import InfoMessage from './InfoMessage';
import actions from '../redux/userDetails/actions';
import { store } from '../redux/store';

const mobileNumberReExp = /^[0-9]{10}$/;
const loanNumberReExp = /^[a-z0-9]{16}$/i;
const emailReExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const otpType = { sms: 'SMS', email: 'Email' };

export default class UserDetailsForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.email || '',
      mobileNumber: this.props.mobileNumber || '',
      loanNumber: this.props.loanNumber || '',
      mobileNumberError: null,
      loanNumberError: null,
      emailError: null,
      messageOption: otpType.sms,
    };
    this.hasErrorEmail = true;
    this.hasErrorMobile = true;
    this.hasErrorLoanNumber = true;
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.email !== this.props.email) {
      this.setState({ email: this.props.email || '' });
    }
    if (prevProps.mobileNumber !== this.props.mobileNumber) {
      this.setState({ mobileNumber: this.props.mobileNumber || '' });
    }
    if (prevProps.loanNumber !== this.props.loanNumber) {
      this.setState({ loanNumber: this.props.loanNumber || '' });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    if (name === 'mobileNumber') {
      if (value === '' || (/^[0-9\b]+$/.test(value) && value.length <= 10)) {
        this.setState({ mobileNumber: value });
      }
    } else if (name === 'loanNumber') {
      if (value === '' || (value.length <= 16 && /^[a-z0-9]+$/i.test(value))) {
        this.setState({ loanNumber: value });
        store.dispatch({
          type: actions.SET_LOAN_NUMBER,
          payload: value,
        });
      }
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  validate = event => {
    event.preventDefault();
    const { email, mobileNumber, loanNumber, messageOption } = this.state;
    if (messageOption === otpType.email && !emailReExp.test(email)) {
      this.setState({
        emailError: 'Please enter a valid email address',
      });
      this.hasErrorEmail = true;
    } else {
      this.setState({ emailError: null });
      this.hasErrorEmail = false;
    }
    if (
      messageOption === otpType.sms &&
      !mobileNumberReExp.test(mobileNumber)
    ) {
      this.setState({
        mobileNumberError: 'Please enter a valid Mobile Number',
      });
      this.hasErrorMobile = true;
    } else {
      this.setState({
        mobileNumberError: null,
      });
      this.hasErrorMobile = false;
    }
    if (!loanNumberReExp.test(loanNumber)) {
      this.setState({
        loanNumberError: 'Please enter a valid Loan A/C number',
      });
      this.hasErrorLoanNumber = true;
    } else {
      this.setState({
        loanNumberError: null,
      });
      this.hasErrorLoanNumber = false;
    }
    this.submitData();
  };

  submitData = () => {
    if (!this.hasErrorLoanNumber) {
      if (
        (this.state.messageOption === otpType.sms && !this.hasErrorMobile) ||
        (this.state.messageOption === otpType.email && !this.hasErrorEmail)
      ) {
        const { email, mobileNumber, loanNumber, messageOption } = this.state;

        let data = {
          exe_tenantId: 'cuecent_tenant',
          owner_tenantId: 'cuecent_tenant',
          inputVariables: {
            in_msg: {
              AccountNo: loanNumber,
            },
          },
        };
        if (messageOption === 'SMS') {
          data.inputVariables.in_msg['MobileNo'] = mobileNumber;
          data.inputVariables.in_msg['EmailId'] = '';
        } else {
          data.inputVariables.in_msg['EmailId'] = email;
          data.inputVariables.in_msg['MobileNo'] = '';
        }
        store.dispatch({
          type: actions.SET_MOBILE_AND_EMAIL_DETAILS,
          mobileNumber: mobileNumber,
          email: email,
        });
        store.dispatch({
          type: actions.SET_ACCOUNT_VALIDATION_DETAILS,
          payload: data,
          otpType: messageOption,
        });
        store.dispatch({
          type: actions.VALIDATE_LOAN_ACCOUNT_NUMBER,
          isFromResendOTP: false,
        });
      }
    }
  };

  render() {
    const {
      validatingLoanNumber,
      loanValidateErrorMessage,
    } = this.props;
    const {
      email,
      mobileNumber,
      mobileNumberError,
      loanNumberError,
      emailError,
      messageOption,
      loanNumber,
    } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-default">
              {loanValidateErrorMessage && (
                <InfoMessage
                  success={!loanValidateErrorMessage}
                  message={loanValidateErrorMessage}
                />
              )}

              <h2 className="heading">Account Details</h2>
              <div className="panel-body">
                <form onSubmit={this.validate}>
                  <div className="form-group">
                    <label htmlFor="payment-options">Loan A/C Number</label>
                    <input
                      type="text"
                      name="loanNumber"
                      className="form-control"
                      onChange={this.handleInputChange}
                      value={loanNumber}
                      autoComplete="off"
                    />
                    {loanNumberError && (
                      <div className="error-msg-existing-customer error">
                        {loanNumberError}
                      </div>
                    )}
                  </div>
                  <div className="radio-inline">
                    <label>
                      <input
                        type="radio"
                        name="messageOption"
                        value="SMS"
                        onChange={this.handleInputChange}
                        checked={messageOption === otpType.sms}
                      />
                      Registered Mobile Number
                    </label>
                  </div>
                  <div className="radio-inline">
                    <label>
                      <input
                        type="radio"
                        name="messageOption"
                        value="Email"
                        onChange={this.handleInputChange}
                        checked={messageOption !== otpType.sms}
                      />
                      Registered Email ID
                    </label>
                  </div>
                  {messageOption === otpType.sms ? (
                    <div className="form-group">
                      <div className="input-group">
                        <span className="input-group-addon" id="basic-addon1">
                          +91
                        </span>
                        <input
                          type="text"
                          name="mobileNumber"
                          className="form-control"
                          onChange={this.handleInputChange}
                          value={mobileNumber}
                          autoComplete="off"
                        />
                      </div>
                      {mobileNumberError && (
                        <div className="error-msg-existing-customer error">
                          {mobileNumberError}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="form-group">
                      <input
                        name="email"
                        type="email"
                        className="form-control"
                        onChange={this.handleInputChange}
                        value={email}
                        autoComplete="off"
                      />
                      {emailError && (
                        <div className="error">{emailError}</div>
                      )}
                    </div>
                  )}
                  <div className="submit-button">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={validatingLoanNumber}
                    >
                      {validatingLoanNumber ? (
                        <Fragment>
                          <i className="fa fa-spinner fa-spin" />{' '}
                          Validating...
                        </Fragment>
                      ) : (
                        'Proceed'
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
