import { all, call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import actions from './actions';
import { postRequest } from '../../helpers/axiosClient';
import { store, history } from '../store';

export function* validateLoanAccountNumber(params) {
  try {
    const { accountDetails } = store.getState().UserDetails;
    const response = yield call(() =>
      postRequest('account/validate', accountDetails),
    );

    if (response.data.out_msg.ErrorCode === '0') {
      yield put({
        type: actions.VALIDATE_LOAN_ACCOUNT_NUMBER_SUCCESS,
        payload: response.data,
        isFromResendOTP: params.isFromResendOTP,
      });
      history.push('/validate');
    } else {
      yield put({
        type: actions.VALIDATE_LOAN_ACCOUNT_NUMBER_FAILURE,
        payload: response.data,
        isFromResendOTP: params.isFromResendOTP,
      });
    }
  } catch (error) {
    if (error?.response?.status !== 401) {
      yield put({
        type: actions.VALIDATE_LOAN_ACCOUNT_NUMBER_FAILURE,
        status: error.response.status,
        isFromResendOTP: params.isFromResendOTP,
      });
    }
  }
}

export function* validateOTP(params) {
  try {
    const { otpType, customerID } = store.getState().UserDetails;
    let data = {
      exe_tenantId: 'cuecent_tenant',
      owner_tenantId: 'cuecent_tenant',
      inputVariables: {
        in_msg: {
          OTP_Service: 'Authentication',
          OTP_Type: otpType,
          CustomerId: customerID,
          GenerateOTP: params.payload,
        },
      },
    };
    const response = yield call(() => postRequest('otp/oauth', data));
    if (response.data.out_msg.ErrorCode === '00') {
      yield put({
        type: actions.VALIDATE_OTP_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: actions.GET_TRANSACTION_STATUS,
      });
    } else {
      yield put({ type: actions.VALIDATE_OTP_FAILURE, payload: response.data });
    }
  } catch (error) {
    if (error?.response?.status !== 401) {
      yield put({ type: actions.VALIDATE_OTP_FAILURE });
    }
  }
}

export function* getPaymentDetails() {
  try {
    const { loanNumber } = store.getState().UserDetails;

    let data = {
      exe_tenantId: 'cuecent_tenant',
      owner_tenantId: 'cuecent_tenant',
      inputVariables: {
        in_msg: {
          LoanAccountNumber: loanNumber,
        },
      },
    };
    const response = yield call(() =>
      postRequest('loanaccount/paymentdetails', data),
    );
    history.push('/payment');
    if (response.data.out_msg.ErrorCode === '0') {
      yield put({
        type: actions.GET_PAYMENT_DETAILS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({ type: actions.GET_PAYMENT_DETAILS_FAILURE });
    }
  } catch (error) {
    history.push('/payment');
    if (error?.response?.status !== 401) {
      yield put({ type: actions.GET_PAYMENT_DETAILS_FAILURE });
    }
  }
}

export function* getTransactionStatus() {
  try {
    const { loanNumber } = store.getState().UserDetails;
    let data = {
      exe_tenantId: 'cuecent_tenant',
      owner_tenantId: 'cuecent_tenant',
      inputVariables: {
        in_msg: {
          LoanAcctNo: loanNumber,
        },
      },
    };
    const response = yield call(() =>
      postRequest('loanaccount/payment/status', data),
    );
    if (response.data.out_msg.ErrorCode !== '0') {
      yield put({
        type: actions.GET_TRANSACTION_STATUS_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: actions.GET_PAYMENT_DETAILS,
      });
    } else {
      yield put({ type: actions.GET_TRANSACTION_STATUS_FAILURE });
      history.push('/payment');
    }
  } catch (error) {
    if (error?.response?.status !== 401) {
      yield put({ type: actions.GET_TRANSACTION_STATUS_FAILURE });
    }
    history.push('/payment');
  }
}

export function* billDeskRedirect(params) {
  try {
    const { loanNumber, mobileNumber, email } = store.getState().UserDetails;
    let data = {
      exe_tenantId: 'cuecent_tenant',
      owner_tenantId: 'cuecent_tenant',
      inputVariables: {
        in_msg: {
          Src_Channel: 'CC',
          RequestDateTime: moment().format('D/M/YYYY, h:mm:ss'),
          LoanAccountNo: loanNumber,
          Amount: params.payload,
          MobileNumber: mobileNumber,
          Email: mobileNumber ? '' : email,
        },
      },
    };
    const response = yield call(() =>
      postRequest('loanaccount/billdesk-initiate', data),
    );
    if (response.data.out_msg.ErrorCode === '0') {
      window.onbeforeunload = function(e) {
        // blank function do nothing
      };
      yield put({
        type: actions.SET_BILL_DESK_REDIRECT_DATA,
        redirectPayload: response.data.out_msg.BD_Request_Msg,
        loanAmount: params.payload,
      });
      window.jQuery('#disclaimerModal').modal('show');
      yield put({
        type: actions.BILL_DESK_REDIRECT_SUCCESS,
      });
    } else {
      yield put({
        type: actions.BILL_DESK_REDIRECT_FAILURE,
        payload: response.data,
      });
    }
  } catch (error) {
    if (error?.response?.status !== 401) {
      yield put({ type: actions.BILL_DESK_REDIRECT_FAILURE });
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.VALIDATE_LOAN_ACCOUNT_NUMBER, validateLoanAccountNumber),
    takeLatest(actions.VALIDATE_OTP, validateOTP),
    takeLatest(actions.GET_PAYMENT_DETAILS, getPaymentDetails),
    takeLatest(actions.BILL_DESK_REDIRECT, billDeskRedirect),
    takeLatest(actions.GET_TRANSACTION_STATUS, getTransactionStatus),
  ]);
}
