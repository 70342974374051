const actions = {
  SET_LOAN_NUMBER: 'SET_LOAN_NUMBER',
  VALIDATE_LOAN_ACCOUNT_NUMBER: 'VALIDATE_LOAN_ACCOUNT_NUMBER',
  VALIDATE_LOAN_ACCOUNT_NUMBER_SUCCESS: 'VALIDATE_LOAN_ACCOUNT_NUMBER_SUCCESS',
  VALIDATE_LOAN_ACCOUNT_NUMBER_FAILURE: 'VALIDATE_LOAN_ACCOUNT_NUMBER_FAILURE',
  VALIDATE_OTP: 'VALIDATE_OTP',
  VALIDATE_OTP_SUCCESS: 'VALIDATE_OTP_SUCCESS',
  VALIDATE_OTP_FAILURE: 'VALIDATE_OTP_FAILURE',
  GET_PAYMENT_DETAILS: 'GET_PAYMENT_DETAILS',
  GET_PAYMENT_DETAILS_SUCCESS: 'GET_PAYMENT_DETAILS_SUCCESS',
  GET_PAYMENT_DETAILS_FAILURE: 'GET_PAYMENT_DETAILS_FAILURE',
  BILL_DESK_REDIRECT: 'BILL_DESK_REDIRECT',
  BILL_DESK_REDIRECT_SUCCESS: 'BILL_DESK_REDIRECT_SUCCESS',
  BILL_DESK_REDIRECT_FAILURE: 'BILL_DESK_REDIRECT_FAILURE',
  GET_TRANSACTION_STATUS: 'GET_TRANSACTION_STATUS',
  GET_TRANSACTION_STATUS_SUCCESS: 'GET_TRANSACTION_STATUS_SUCCESS',
  GET_TRANSACTION_STATUS_FAILURE: 'GET_TRANSACTION_STATUS_FAILURE',
  SET_MOBILE_AND_EMAIL_DETAILS: 'SET_MOBILE_AND_EMAIL_DETAILS',
  SET_BILL_DESK_REDIRECT_DATA: 'SET_BILL_DESK_REDIRECT_DATA',
  SET_ACCOUNT_VALIDATION_DETAILS: 'SET_ACCOUNT_VALIDATION_DETAILS',
  RESET_REDUCER_STATE: 'RESET_REDUCER_STATE',
};

export default actions;
